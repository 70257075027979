import {backendApi} from "../../backend-api-sr"

export const purchasingPerfomance = (() =>{
    const fetchIndex = ( async (subPath, body) => {
        var url = '/api/v3/sm/purchasing'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const fetchData = ( async (subPath, body) => {
        var url = '/api/v3/sm/purchasing/show-data'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const fetchDataPo = ( async (subPath, body) => {
        var url = '/api/v3/sm/purchasing/show-data-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const fetchDetailPr = ( async (subPath, body) => {
        var url = '/api/v3/sm/purchasing/show-detail-pr'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    const fetchDetailPo = ( async (subPath, body) => {
        var url = '/api/v3/sm/purchasing/show-detail-po'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    return {fetchIndex, fetchData, fetchDataPo, fetchDetailPr, fetchDetailPo};
})()